import "../src/shared/wrap_ready.js"
import Cookie from "../src/shared/cookie";

window.Elionline = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Elionline.Cookie = new Cookie();
});

import "../../whitelabels/elionline/assets/javascripts/application.js";
