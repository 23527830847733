$(function () {
  // hide fullname if avatar is clicked and show when not clicked
  $('#drop-avatar').change(function() {
    if (this.checked) {
      $('#fullname-header').hide();
    } else {
      $('#fullname-header').show();
    }
  });
});
